














import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class CmxPopUp extends Vue {
  content: object = {};
  agreeBtn:string='';
  declineBtn:string='';
  footshow:boolean=false;
  // 获取关于我们cms内容方法
  getContent () {
    this.$Api.cms
      .getContentByDevice({ ContentId: 20312, IsMobile: false })
      .then(result => {
        this.content = result.CMS;
      });
  }
  getagreeBtn () {
    this.$Api.cms
      .getContentByDevice({ ContentId: 20315, IsMobile: false })
      .then(result => {
        this.agreeBtn = result.CMS;
      });
  }
  getdeclineBtn () {
    this.$Api.cms
      .getContentByDevice({ ContentId: 20316, IsMobile: false })
      .then(result => {
        this.declineBtn = result.CMS;
      });
  }
  closeEvent () {
    this.$emit('closeEvent');
  }
  agree () {
    this.$emit('agree');
  }
  decline () {
    this.$emit('decline');
  }
  mounted () {
    this.getContent();
    setTimeout(() => {
      this.footshow = true;
    }, 2000);
    this.getagreeBtn();
    this.getdeclineBtn();
  }
}
